

















































































import { Vue, Component, Mixins } from 'vue-property-decorator'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

import MyBox from '@/components/box.vue'

@Component({
  components: {
    MyDvBox,
    MyBox,
  },
})
export default class extends Mixins() {}
